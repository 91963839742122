<template>
  <Layout>
    <el-main class="center-container-MT">
      <div class="center-layout-MT">
        <div class="body-title-layout" style="padding-left: 0">
          <div class="divider-3"></div>
          <div class="body-preview-title-content-style">在线学习</div>
        </div>
        <el-scrollbar class="record-items-container">
          <div class="record-item-container"
               v-for="(item,index) in examList"
               :key="index"
               :style="index===0?'margin-top:0;':'margin-top: 25px;'">
            <div class="time-record-layout">
              <div class="origin-style-TR"></div>
              <div class="time-content-TR">更新于 {{ item.createTime }}</div>
            </div>
            <div class="test-record-Info-container" @click="getAnswerContent(item.id)">
              <div class="test-problem-layout-TRI" style="padding-left: 30px;padding-top:0;line-height: 100px;">
                <span class="type-TR-TRI" style="line-height: 100%;">{{ item.examName }}</span>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </el-main>
  </Layout>
</template>
<script>
import Layout from "@/components/Layout"

export default {
  name: "PersonalCenter",
  components: {Layout},
  data() {
    return {
      isPersonalCenterShow: true,
      isMyTestShow: false,
      isAnswerShow: false,
      examList: []
    }
  },
  methods: {
    getExamList() {
      this.axios.get('/jeecg-boot/humanExam/list').then(res => {
        this.examList = res.result.records
      })
    },
    getAnswerContent(id) {
      this.$router.push({path: '/answer', query: {id: id}})
    }
  },
  mounted() {
    this.getExamList()
  }
}
</script>

<style>
</style>
