<template>
  <div class="main-container-PL">
    <Header :personalInfo="false"></Header>
    <div class="main-container">
      <personal-left/>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import PersonalLeft from "@/components/PersonalLeft"
import Header from "@/components/Header";


export default {
  name: "Layout",
  components:{PersonalLeft,Header}
}
</script>

<style scoped>
.main-container {
  width: 1100px;
  height: 800px;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  /*background: #EBF3F4;*/
  background: #f8f8f8;
  border-radius: 8px;
}

</style>
