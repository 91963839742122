<template>
  <div class="left-container-PL">
    <!--        <router-link id="clickLH" class="personal-center-button" :to="{path:'/personal_center',query:{isPSShow: true}}">-->
    <!--          <div class="personal-center-click" @click="switchStyle(1)">-->
    <!--            <img src="../assets/img/personal_center/user_icon.png" id="iconLH">-->
    <!--            <div style="margin-top: 18px"><span id="LH_word" class="style-content-cancel-A">个人中心</span></div>-->
    <!--          </div>-->
    <!--        </router-link>-->
    <!--    <router-link id="clickMT" class="personal-center-button" style="background: none;margin-top: 30px"-->
    <!--                 :to="{path:'/personal_center',query:{test:' '}}">-->
    <!--      <div class="personal-center-click" @click="switchStyle(2)">-->
    <!--        <img src="../assets/img/personal_center/ceshi.png" id="MTCmpIcon">-->
    <!--        <div style="margin-top: 18px"><span id="answerCmpWord" class="style-content-cancel-A" style="color: white">我的测试</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </router-link>-->
    <!--    <router-link :to="{path:'/personal_center',query:{isAnswerShow: true}}"-->
    <!--                 id="clickED" class="personal-center-button" style="background: none;margin-top: 30px">-->
    <!--      <div class="personal-center-click" @click="switchStyle(3)">-->
    <!--        <img src="../assets/img/personal_center/tiku2.png" id="examDBIcon">-->
    <!--        <div style="margin-top: 18px"><span id="examDBWord" class="style-content-cancel-A">仿真答题</span></div>-->
    <!--      </div>-->
    <!--    </router-link>-->
    <div class="personal-center-click" :class="{active:$route.path===item.path}" v-for="(item,index) in leftNav" @click="handleToPage(item.path)">
      <img :src="item.icon">
      <div style="margin-top: 18px"><span id="examDBWord" class="style-content-cancel-A">{{ item.navName }}</span></div>
    </div>
  </div>
</template>

<script>
import tiku from "@/assets/img/personal_center/tiku2.png"
import ceshi from "@/assets/img/personal_center/ceshi.png"

export default {
  name: "PersonalLeft",
  data() {
    return {
      leftNav: [
        {
          navName: '仿真答题',
          path: '/examPaperList',
          icon: tiku
        },
        {
          navName: '我的测试',
          path: '/examHistoryList',
          icon: ceshi
        },

      ],
    }
  },
  methods:{
    handleToPage(path){
      this.$router.push({path:path})
    }
  }
}
</script>

<style scoped>
.style-content-cancel-A {
  width: 66px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  /*color: #089BAB;*/
  color: #fff;
}
.active .style-content-cancel-A{
  color: #089BAB;
}
.active {
  width: 85px;
  background: #FFFFFF;
  border-radius: 49px;
  text-decoration: none;
  color: #089BAB;
}

.personal-center-click {
  width: 85px;
  border-radius: 40px;
  margin-top: 40px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}

</style>
